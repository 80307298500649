import React, { useRef, useEffect, useState } from "react";
import { WaiataTypeData } from "@plinknz/tah-website-elements";

interface WaiataProps {
    data: WaiataTypeData;
}

interface LineProps {
    startTime: number;
    endTime: number;
    timings: number[];
    words: string[];
}

export const Waiata = ({ data }: WaiataProps) => {
    const [lines, setLines] = useState([]);
    const [highlightedWords, setHighlightedWords] = useState([]);
    const [unhighlightedWords, setUnhighlightedWords] = useState([]);
    const [currentLine, setCurrentLine] = useState({
        highlighted: " ",
        unhighlighted: " ",
    });

    const audioRef = useRef<HTMLAudioElement>(null);

    const parseKupu = (kupu: string) => {
        const allLines = kupu.split("::");
        const newUnhighlightedWords: string[] = [];
        const linesData: LineProps[] = [];

        allLines.forEach((line) => {
            const lineStrings = line.split(":");
            const allTimes: number[] = [];
            const allWords: string[] = [];

            for (let i = 0; i < lineStrings.length; i += 1) {
                if (i % 2 === 0) {
                    allTimes.push(parseFloat(lineStrings[i]));
                } else {
                    allWords.push(lineStrings[i]);
                }
            }
            linesData.push({
                startTime: allTimes[0],
                endTime: allTimes[allTimes.length - 1],
                timings: allTimes,
                words: allWords,
            });
            newUnhighlightedWords.push(allWords.join(" "));
        });
        setLines(linesData);
        setUnhighlightedWords(newUnhighlightedWords.slice(0, 5));
    };

    const handleTime = () => {
        const newCurrentLine = { highlighted: " ", unhighlighted: " " };
        const newHighlightedWords: string[] = [];
        const newUnhighlightedWords: string[] = [];

        if (!audioRef.current && lines.length > 0) {
            return;
        }

        for (let i = 0; i < lines.length; i += 1) {
            const line = lines[i];
            if (audioRef.current.currentTime > line.endTime) {
                // This line has already played
                newHighlightedWords.push(line.words.join(" "));
            } else if (audioRef.current.currentTime >= line.startTime) {
                // Current Line
                const words = line.words.slice(0);

                for (let a = 0; a < line.timings.length; a += 1) {
                    const timing = line.timings[a];
                    const word = words.shift();
                    if (timing < audioRef.current.currentTime) {
                        newCurrentLine.highlighted += ` ${word}`;
                    } else {
                        newCurrentLine.unhighlighted = ` ${word} ${words.join(
                            " "
                        )}`;
                        break;
                    }
                }
            } else {
                // This line has not yet played
                newUnhighlightedWords.push(line.words.join(" "));
            }
        }

        const currentLineCount =
            newCurrentLine.unhighlighted === " " &&
            newCurrentLine.highlighted === " "
                ? 0
                : 1;
        const highlightLineCount = Math.min(
            newHighlightedWords.length,
            5 - currentLineCount - Math.min(2, newUnhighlightedWords.length)
        );
        const unhighlightLineCount = Math.min(
            newUnhighlightedWords.length,
            5 - currentLineCount - highlightLineCount
        );

        setHighlightedWords(newHighlightedWords.slice(-highlightLineCount));
        setUnhighlightedWords(
            newUnhighlightedWords.slice(0, unhighlightLineCount)
        );
        setCurrentLine(newCurrentLine);
    };

    useEffect(() => {
        if (!audioRef.current) {
            return;
        }
        audioRef.current.ontimeupdate = handleTime;
    }, [audioRef.current]);

    useEffect(() => {
        parseKupu(data.timed_kupu);
    }, []);

    return (
        <div className="waiata-heading">
            <h3 data-testid="waiata-title">{data.title}</h3>
            <div
                className="waiata-inner || constrain-width"
                data-testid="waiata">
                <div className="waiata-text">
                    {highlightedWords.map((line) => (
                        <p
                            className="waiata-text"
                            data-testid="highlighted-word">
                            <strong>{line}</strong>
                        </p>
                    ))}
                    <p className="waiata-text">
                        <strong>{currentLine.highlighted}</strong>
                        {currentLine.unhighlighted}
                    </p>

                    {unhighlightedWords.map((line) => (
                        <p className="waiata-text">{line}</p>
                    ))}
                </div>
            </div>
            <audio
                controls
                ref={audioRef}
                className="waiata-container audio"
                src={data.audio_file.url}>
                <track kind="captions" />
            </audio>
        </div>
    );
};
